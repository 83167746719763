<template>
	<div>
		<v-data-table
			:headers="getHeaders('berth')"
			:items="berths ? berths : []"
			:single-expand="singleExpand"
			:expanded.sync="expanded"
			item-key="id"
			show-expand
			:show-select="false"
			:items-per-page="10"
			class="elevation-1"
			style="overflow-y: scroll !important"
		>
			<template v-slot:expanded-item="{ headers, item }">
				<td :colspan="headers.length" class="px-1">
					<v-data-table
						:headers="getHeaders('prediction')"
						:items="item['predictions']"
						hide-default-footer
						class="elevation-1 my-1"
						style="width: 100%"
					></v-data-table>
				</td>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import { query_getAllData } from '@/utils/queries';
import { lastData } from '../../../api/databases_API';

export default {
	name: 'bpaposidoniawebportcall',
	components: {},
	props: {},
	data() {
		return {
			model: 'bpaposidoniawebportcall',
			singleExpand: false,
			expanded: [],
			berths: []
		};
	},
	computed: {},
	watch: {},
	created() {
		this.getData();
	},
	mounted() {},
	methods: {
		deviceParameters(type) {
			return this.$store.getters.getParameters.find((parameter) => parameter.elasticacronym == type);
		},
		getHeaders(type) {
			let keys = [];
			if (type === 'berth') {
				keys = [
					'data-table-expand',
					'año',
					'puerto',
					'escala',
					'gt',
					'imo',
					'buque',
					'serviciomaritimo',
					'fechaatraquesolicitada',
					'fechadesatraquesolicitada',
					'codmuellesolicitado',
					'codmuellereal',
					'codestadoatraque',
					'estadoatraque'
				];
			} else if (type === 'prediction') {
				keys = ['station', 'no224', 'no248', 'no272', 'o324', 'o348', 'o372', 'so224', 'so248', 'so272'];
			}
			const mappedArray = keys.map((key) => {
				return { text: this.$t('grid.bpaposidoniawebportcall.' + key), value: key };
			});

			return mappedArray;
		},
		getData() {
			let data = [];
			let indexName = 'sp_bpa_posidoniaweb_portcall';
			let promise = lastData(indexName, query_getAllData());
			let no2unit = this.deviceParameters('no2');
			let o3unit = this.deviceParameters('o3');
			let so2unit = this.deviceParameters('so2');
			promise.then((elasticData) => {
				console.warn(elasticData);
				elasticData.message.forEach((berth) => {
					let predictions = [];
					berth['_source']['predictions'].forEach((prediction) => {
						if (prediction['no2'].length == 1) {
							let no272 = prediction['no2'][0];
							prediction['no2'] = ['-', '-', no272];
						} else if (prediction['no2'].length == 2) {
							let no248 = prediction['no2'][0];
							let no272 = prediction['no2'][1];
							prediction['no2'] = ['-', no248, no272];
						}
						if (prediction['so2'].length == 1) {
							let so272 = prediction['so2'][0];
							prediction['so2'] = ['-', '-', so272];
						} else if (prediction['so2'].length == 2) {
							let so248 = prediction['so2'][0];
							let so272 = prediction['so2'][1];
							prediction['so2'] = ['-', so248, so272];
						}
						if (prediction['o3'].length == 1) {
							let o372 = prediction['o3'][0];
							prediction['o3'] = ['-', '-', o372];
						} else if (prediction['o3'].length == 2) {
							let o348 = prediction['o3'][0];
							let o372 = prediction['o3'][1];
							prediction['o3'] = ['-', o348, o372];
						}
						let data = {
							station: prediction['station'],
							no224: isNaN(parseFloat(prediction['no2'][0]))
								? '-'
								: parseFloat(prediction['no2'][0]).toFixed(2) + ' - ' + no2unit.symbol,
							no248: isNaN(parseFloat(prediction['no2'][1]))
								? '-'
								: parseFloat(prediction['no2'][1]).toFixed(2) + ' - ' + no2unit.symbol,
							no272: isNaN(parseFloat(prediction['no2'][2]))
								? '-'
								: parseFloat(prediction['no2'][2]).toFixed(2) + ' - ' + no2unit.symbol,
							o324: isNaN(parseFloat(prediction['o3'][0])) ? '-' : parseFloat(prediction['o3'][0]).toFixed(2) + ' - ' + o3unit.symbol,
							o348: isNaN(parseFloat(prediction['o3'][1])) ? '-' : parseFloat(prediction['o3'][1]).toFixed(2) + ' - ' + o3unit.symbol,
							o372: isNaN(parseFloat(prediction['o3'][2])) ? '-' : parseFloat(prediction['o3'][2]).toFixed(2) + ' - ' + o3unit.symbol,
							so224: isNaN(parseFloat(prediction['so2'][0]))
								? '-'
								: parseFloat(prediction['so2'][0]).toFixed(2) + ' - ' + so2unit.symbol,
							so248: isNaN(parseFloat(prediction['so2'][1]))
								? '-'
								: parseFloat(prediction['so2'][1]).toFixed(2) + ' - ' + so2unit.symbol,
							so272: isNaN(parseFloat(prediction['so2'][2]))
								? '-'
								: parseFloat(prediction['so2'][2]).toFixed(2) + ' - ' + so2unit.symbol
						};
						predictions.push(data);
					});
					if (typeof berth == 'object') {
						data = {
							id: berth['_source']['id'],
							año: berth['_source']['año'] == '' ? '-' : berth['_source']['año'],
							puerto: berth['_source']['puerto'] == '' ? '-' : berth['_source']['puerto'],
							escala: berth['_source']['escala'] == '' ? '-' : berth['_source']['escala'],
							gt: berth['_source']['gt'] == '' ? '-' : berth['_source']['gt'],
							imo: berth['_source']['imo'] == '' ? '-' : berth['_source']['imo'],
							buque: berth['_source']['buque'] == '' ? '-' : berth['_source']['buque'],
							serviciomaritimo: berth['_source']['serviciomaritimo'] == '' ? '-' : berth['_source']['serviciomaritimo'],
							fechaatraquesolicitada:
								berth['_source']['fechaatraquesolicitada'] == '' ? '-' : berth['_source']['fechaatraquesolicitada'],
							fechadesatraquesolicitada:
								berth['_source']['fechadesatraquesolicitada'] == '' ? '-' : berth['_source']['fechadesatraquesolicitada'],
							codmuellesolicitado: berth['_source']['codmuellesolicitado'] == '' ? '-' : berth['_source']['codmuellesolicitado'],
							fechaatraquereal: berth['_source']['fechaatraquereal'] == '' ? '-' : berth['_source']['fechaatraquereal'],
							fechadesatraquereal: berth['_source']['fechadesatraquereal'] == '' ? '-' : berth['_source']['fechadesatraquereal'],
							codmuellereal: berth['_source']['codmuellereal'] == '' ? '-' : berth['_source']['codmuellereal'],
							codestadoatraque: berth['_source']['codestadoatraque'] == '' ? '-' : berth['_source']['codestadoatraque'],
							estadoatraque: berth['_source']['estadoatraque'] == '' ? '-' : berth['_source']['estadoatraque'],
							predictions: predictions
						};
						this.berths.push(data);
					}
				});
				console.error(this.berths);
				this.getItems();
			});
		}
	}
};
</script>

<style lang="postcss">
.v-data-table {
	& span {
		font-size: 1rem;
	}

	& td {
		font-size: 1rem;
	}
}
</style>
